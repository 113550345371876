import React from 'react'

import styles from './lead.module.scss'

type Props = Lead

export const Lead: React.FC<Props> = ({ headline, content }) => (
  <div className={`${styles.copy} cell large-offset-1 large-5`}>
    <h2>{headline}</h2>
    {content}
  </div>
)
