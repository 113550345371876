import React from 'react'

import Section from '../../layout/Section'
import Technology from '../../components/Technology'

import styles from './technologies-section.module.scss'

interface Props {
  technologies: SectionItems<Technology>
}

export const TechnologiesSection: React.FC<Props> = ({ technologies }) => (
  <>
    <Section
      white
      headline={technologies.headline}
      containerClassName='mobile-horizontal-scroll-container'
      bottomPadding='normal'
      topPadding='xlarge'
    >
      <div className={
        `${styles.gridContainer} grid-x grid-margin-x grid-margin-y mobile-horizontal-scroll`
      }>
        {technologies.items.map(technology => (
          <Technology key={technology.name} technology={technology}/>
        ))}
      </div>
    </Section>
  </>
)
