import React from 'react'

import Section from '../../layout/Section'
import Review from '../../components/ClientReview'

import styles from './reviews-section.module.scss'

interface Props {
  reviews: SectionItems<Review>
}

export const ReviewsSection: React.FC<Props> = ({ reviews }) => (
  <Section
    headline={reviews.headline}
    containerClassName={styles.fullWidthHorizontalScrollContainer}
    bottomPadding='normal'
    topPadding='normal'
    light
  >
    <div className={`${styles.gridContainer} grid-x grid-margin-x`}>
      {reviews.items.map(review => <Review key={review.clientName} review={review}/>)}
    </div>
  </Section>
)
