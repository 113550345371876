import { graphql } from 'gatsby'

import { Lead } from './Lead'

export default Lead

export const query = graphql`
  fragment LeadFragment on ContentfulTextBlock {
    headline
    text {
      text
    }
  }
`
