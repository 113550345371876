import { graphql } from 'gatsby'

import { Service } from './Service'

export default Service

export const query = graphql`
  fragment ServiceFragment on ContentfulService{
    icon {
      fixed(width: 64) {
        ...GatsbyContentfulFixed
      }
      ... FixedImageFragment
    }
    name
    copy {
      copy
    }
  }
`
