import React from 'react'

import CallToAction from '../../components/CallToAction'
import Service from '../../components/Service'
import Section from '../../layout/Section'

import styles from './services-section.module.scss'

type Props = SectionItems<Service>

export const ServicesSection: React.FC<Props> = ({ headline, items }) => (
  <Section
    headline={headline}
    bottomPadding='none'
    addons={addons}
    light
    className={styles.servicesSection}
  >
    <Services items={items}/>
    <div className={styles.calltoAction}>
      <CallToAction
        headline='Tell us what you have in mind...'
        subheadline='Looking for a new partner?'
        addon={{
          addon: (
            <img
              src='/images/illustrations/planet1.svg'
              alt='Planet used as section decoration'
            />
          ),
          className: styles.ctaAddon,
        }}
      />
    </div>
  </Section>
)

const addons = {
  top: {
    addon: <img src='/images/illustrations/stardust.svg' alt='Stardust illustration'/>,
    top: '-51px',
  },
}

const Services: React.FC<{ items: Service[] }> = ({ items }) => (
  <div className={`${styles.services} grid-x grid-margin-x grid-margin-y`}>
    {items.map((service, idx) => (
      <Service key={idx} className='cell large-4' {...service}/>
    ))}
  </div>
)
