import React from 'react'

import Card from '../../components/Card'

import styles from './review-card.module.scss'

export const ReviewCard: React.FC = () => (
  <Card
    className={`${styles.reviewCard} cell large-offset-1 large-4`}
    dark
    addonTop={{
      addon: <img src='/images/clutch-badge.png' alt='Top web developers on clutch badge'/>,
      top: '-74px',
    }}
    addonBottom={{
      addon: (
        <a href='https://clutch.co/profile/pinkdroids#reviews'
          target='_blank' rel='noopener noreferrer' className='btn btn--sm'
        >
          <span className='h5 h5--small dark-bg'>Read reviews</span>
          &nbsp;
          <img src='/images/icons/arrow-right.svg' alt='Arrow pointing right'/>
        </a>
      ),
      bottom: '-19px',
    }}
  >
    <div className={styles.reviewContent}>
      <div className={styles.reviewContentTop}>
        <h6 className='pink'>SILVER VERIFIED</h6>
      </div>
      <div className={styles.reviewContentBottom}>
        <div className={styles.reviewStars}>
          <div className={styles.star}>&nbsp;</div>
          <div className={styles.star}>&nbsp;</div>
          <div className={styles.star}>&nbsp;</div>
          <div className={styles.star}>&nbsp;</div>
          <div className={styles.star}>&nbsp;</div>
        </div>
        <div className='h1'>4.9</div>
        <p className='pink text-center'><b>
          Straight A&apos;s for quality and willingness to refer
        </b></p>
      </div>
    </div>
  </Card>
)
