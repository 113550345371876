import React, { ReactElement, Fragment } from 'react'
import Img from 'gatsby-image'

import Card from '../Card'

import styles from './service.module.scss'

interface Props extends Service {
  className?: string
}

export const Service: React.FC<Props> = ({ className = '', icon, name, copy }) => (
  <Card className={`${styles.service} ${className || ''}`}>
    <div className={styles.icon}>
      <Img fixed={icon.fixed} alt={icon.alt || `${name} icon`}/>
    </div>
    <h4 className='purple text-center'>{ name }</h4>
    <p>{formatCopy(copy)}</p>
  </Card>
)

const formatCopy = (copy: string): ReactElement => {
  const rows = copy.split('\n').filter(row => {
    return row.trim().length > 0
  })
  const formatted = rows.map((row, idx) =>
    <Fragment key={idx}>{row}{idx < rows.length - 1 && <br/>}</Fragment>,
  )
  return <>{ formatted }</>
}
