import { graphql } from 'gatsby'

import { ClientReview } from './ClientReview'

export const query = graphql`
  fragment ReviewFragment on ContentfulReview {
    id
    clientName: client
    clientLogo {
      ... FullImageFragment
    }
    location
    quote {
      quote
    }
    reviewUrl
    quoteAuthor
    quoteAuthorRole
    date
    score
  }
`

export default ClientReview
