import { graphql } from 'gatsby'

import { Technology } from './Technology'

export const query = graphql`
  fragment TechnologyFragment on ContentfulTechnology {
    id
    name
    logo {
      ... FullImageFragment
    }
  }
`

export default Technology
