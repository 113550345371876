import React from 'react'
import { Link as ScrollLink } from 'react-scroll'

import { noopFunction } from '../../constants'
import Card from '../Card'

import styles from './call-to-action.module.scss'

interface Props {
  headline: string
  subheadline: string
  onClick?: VoidFunction
  className?: string
  addon?: Addon
  withBackground?: boolean
  isVertical?: boolean
}

export const CallToAction: React.FC<Props> = ({
  headline,
  subheadline,
  onClick = noopFunction,
  className = '',
  addon,
  withBackground = false,
  isVertical = false,
}) => (
  <Card
    className={`${styles.ctaCard} ${className}`}
    dark
    addon={addon}
  >
    {withBackground && <div className={styles.illustrationBg}/>}
    <div className={`${styles.cta} ${isVertical ? styles.ctaVertical : ''}`}>
      <div className={`${styles.copy}`}>
        <p className='pink'><strong>{subheadline}</strong></p>
        <h2 className='dark-bg'>{headline}</h2>
      </div>
      {!withBackground && (
        <div className={`${styles.illustration}`}>
          <img src='/images/illustrations/star-cluster.svg' alt='Star cluster illustration'/>
        </div>
      )}
      <div className={`${styles.button}`}>
        <ScrollLink
          to='contact-section'
          className='btn btn--lg btn--red'
          smooth={true}
          onClick={onClick}
        >
          GET IN TOUCH
        </ScrollLink>
      </div>
    </div>
  </Card>
)
