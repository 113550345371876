import React from 'react'
import { graphql } from 'gatsby'

import { processContext } from '../contentful'
import { ContactElement } from '../enums'
import WhatWeDo from '../page-components/WhatWeDo'

interface Props {
  data: PageQueryData
}

const WhatWeDoPage: React.FC<Props> = ({ data }) => (
  <WhatWeDo
    pageContext={{
      ...processContext(data),
      contactLinkOptions: { element: ContactElement.ContactSection },
    }}
  />
)

export default WhatWeDoPage

export const query = graphql`
  query {
    page: allContentfulPage(filter: {slug: {eq: "what-we-do"}}) {
      edges {
        node {
          ... PageFragment
        }
      }
    }
  }`
