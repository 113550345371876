// TODO Rework this component
// Relies on rigid expectation on data type
// Should have smoother transition on small and medium
// Support different content than ReviewCard
// ReviewCard does not use any of the props from ContenfulReview, everythin is hardcoded
import React from 'react'
import Img from 'gatsby-image'
import { Element } from 'react-scroll'

import Lead from '../../components/Lead'
import { processLeadData, processImageFluid } from '../../contentful'
import Section from '../../layout/Section'
import { ReviewCard } from './ReviewCard'

import styles from './lead-section.module.scss'

interface Props {
  leadData?: SectionSource<LeadSource>
}

export const LeadSection: React.FC<Props> = ({ leadData }) => {
  const lead = processLeadData(leadData)
  const element = extractElement(leadData)
  return (
    <>
      <Element name='first-section'></Element>
      <Section white grid topPadding='large' bottomPadding='large'>
        <Lead headline={lead.headline} content={lead.content}/>
        { element }
      </Section>
    </>
  )
}

const imageClassName = [
  styles.image,
  'cell',
  'small-offset-1',
  'small-10',
  'medium-offset-3',
  'medium-6',
  'large-offset-1',
  'large-5',
].join(' ')

const extractElement = (leadData?: SectionSource<LeadSource>): (JSX.Element | undefined) => {
  const isReview = (
    leadData &&
    leadData.items.length > 1 &&
    leadData.items[1].__typename === 'ContentfulReview'
  )
  if (isReview) {
    return <ReviewCard/>
  } else if (leadData && leadData.image) {
    const image = processImageFluid(leadData.image)
    return <Img
      className={imageClassName}
      fluid={image.fluid}
      alt={image.alt || 'Section illustration'}
    />
  }
  return undefined
}
