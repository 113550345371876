import React from 'react'

import Card from '../Card'

import styles from './technology.module.scss'

interface Props {
  technology: Technology
}

export const Technology: React.FC<Props> = ({ technology }) =>
  <Card className={`${styles.technology} cell large-3 medium-4`}>
    <img src={technology.logo.url} alt={technology.logo.alt || technology.name}/>
    <h6 className={styles.name}>{technology.name}</h6>
  </Card>
