import React from 'react'

import { SmallHero } from '../../components/Hero'
import Contact from '../../components/Contact'
import {
  extractReviewSection,
  extractServiceSection,
  extractTechnologySection,
} from '../../contentful'
import { SectionType as SectionTypeEnum } from '../../enums'
import Layout from '../../layout/Layout'
import { LeadSection } from '../Home/LeadSection'
import { ServicesSection } from '../Home/ServicesSection'
import { ReviewsSection } from './ReviewsSection'
import { TechnologiesSection } from './TechnologiesSection'

import styles from './what-we-do.module.scss'

interface Props {
  pageContext: PageContext
}

export const WhatWeDo: React.FC<Props> = ({ pageContext }) => {
  const { lead, technologies, reviews, services } = extractSections(pageContext.sections)
  return (
    <Layout pageContext={pageContext}>
      <SmallHero
        headline={pageContext.headline}
        addon={{
          addon: <img src='/images/illustrations/shuttle.svg' alt='Planet illustration'/>,
          className: styles.heroAddon,
        }}
      />
      <LeadSection leadData={lead}/>
      <ServicesSection headline={services.headline} items={services.items}/>
      <TechnologiesSection technologies={technologies}/>
      <ReviewsSection reviews={reviews}/>
      <Contact/>
    </Layout>
  )
}

interface Sections {
  technologies: SectionItems<Technology>
  reviews: SectionItems<Review>
  services: SectionItems<Service>
  lead?: SectionSource<LeadSource>
}

const extractSections = (sections: SectionSource[]): Sections => {
  const technologyData = sections.find(section => section.type === SectionTypeEnum.Technology)
  const reviewData = sections.find(section => section.type === SectionTypeEnum.Review)
  const serviceData = sections.find(section => section.type === SectionTypeEnum.Service)
  const lead = sections.find(section => section.type === SectionTypeEnum.Lead)

  return {
    reviews: extractReviewSection(reviewData as SectionItemsSource<ReviewSource>),
    technologies: extractTechnologySection(technologyData as SectionItemsSource<TechnologySource>),
    services: extractServiceSection(serviceData as SectionItemsSource<ServiceSource>),
    lead: lead as SectionSource<LeadSource>,
  }
}
