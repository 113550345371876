import React from 'react'

import Card from '../Card'

import styles from './review.module.scss'

interface Props {
  review: Review
}

export const ClientReview: React.FC<Props> = ({ review }) =>
  <Card className='cell medium-6 small-12'>
    <a className={styles.review} href={review.reviewUrl} target='_blank' rel='noopener noreferrer'>
      <div className={styles.quoteIcon}></div>
      <h4 className={styles.quote}>&quot;{review.quote.quote}&quot;</h4>
      <div className={styles.client}>
        <img
          src={review.clientLogo.url}
          alt={review.clientLogo.alt || `${review.clientName} logo`}
        />
        {review.quoteAuthor && <span className='h7'>{review.quoteAuthor}</span>}
        <p className={`${styles.clientInfo} copy--sm`}>
          {`${review.quoteAuthorRole && `${review.quoteAuthorRole}, `}${review.clientName}`}
        </p>
        <p className='h6 light copy--normal pink'>{review.location}</p>
      </div>
    </a>
  </Card>
